import React from "react";

const LanguageSwitchButton = ({ toggleLanguage, language }) => {
  return (
    <div className="wrapper">
        <div className="cont">
          <label>
            {/* Language Switch Checkbox */}
            <input
              type="checkbox"
              onChange={toggleLanguage}
              checked={language === "en"}
            />
          </label>
        </div>
    </div>
  );
};

export default LanguageSwitchButton;

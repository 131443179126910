import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

function SecondSlider({ currentIndex, direction, paginate, projects, language }) {
  if (!projects || projects.length === 0) {
    return <div>No projects available.</div>;
  }

  const currentProject = projects[currentIndex];
  if (!currentProject) {
    return <div>Invalid project index.</div>;
  }

  const techList = currentProject.tech ? Object.values(currentProject.tech) : [];

  return (
    <div className="second-slider">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          className="project-second-slide"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset }) => {
            if (offset.x > 100) paginate(-1); // Swipe right to go previous
            if (offset.x < -100) paginate(1); // Swipe left to go next
          }}
        >
          <div
            className="card bg-dark text-white bg-opacity-75 project-second-slide"
            style={{
              width: "90%",
              maxWidth: "80vh",
              top: "20px",
            }}
          >
            {/* Title image */}
            {currentProject?.titleimage && (
              <img
                src={currentProject.titleimage}
                alt={currentProject?.title[language] || "Default Title"}
                className="card-img-top"
              />
            )}

            {/* Card body */}
            <div className="card-body">
              <h5
                className="card-title"
                style={{
                  cursor: "pointer",
                  fontFamily: "'Lucida Console', sans-serif",
                  fontSize: "2rem",
                }}
              >
                {currentProject?.title[language] || "Default Title"}
              </h5>
              <p
                className="card-text"
                style={{
                  cursor: "pointer",
                  fontFamily: "'Lucida Console', sans-serif",
                  fontSize: "1rem",
                }}
              >
                {currentProject?.descriptionBackend[language] || "No description available."}
              </p>

              {/* Tech list */}
              {techList.length > 0 && (
                <div
                  className="accordion tech-list d-flex flex-wrap gap-2 mt-3 text-black"
                  id="techAccordion"
                >
                  {techList.map((tech, index) => {
                    const techName = tech.name?.[language] || tech.name?.fr || tech.name?.en || "Tech";
                    const techDesc = tech.description?.[language] || "No description available.";
                    const iconPath = "/img/icons/" + tech.icon + ".svg" || "default-tech-icon.svg";

                    // Unique ID for collapsible content
                    const collapseId = `collapse-${index}`;

                    return (
                      <div
                        key={index}
                        className="accordion-item tech-item m-1 p-2 bg-light text-center rounded"
                        style={{
                          cursor: "pointer",
                          fontFamily: "'Lucida Console', sans-serif",
                          fontSize: "1rem",
                        }}
                      >
                        <div
                          className="accordion-header"
                          id={`heading-${index}`}
                          data-bs-toggle="collapse"
                          data-bs-target={`#${collapseId}`}
                          aria-expanded="false"
                          aria-controls={collapseId}
                        >
                          {/* Icon */}
                          <img
                            src={iconPath}
                            alt={`${techName}`}
                            style={{ width: "60px", height: "60px" }}
                          />
                          {/* Tech Name */}
                          <div className="mt-2">
                            <strong>{techName}</strong>
                          </div>
                        </div>

                        {/* Collapsible Description */}
                        <div
                          id={collapseId}
                          className="accordion-collapse collapse"
                          aria-labelledby={`heading-${index}`}
                          data-bs-parent="#techAccordion"
                        >
                          <div className="accordion-body bg-white text-dark p-2 rounded shadow">
                            <small>{techDesc}</small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {currentProject?.github && (
                <div className="mx-auto mt-3">
                  <a
                    href={currentProject.github}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on GitHub
                  </a>
                </div>
              )}
              {currentProject?.site && (
                <div className="mx-auto mt-3">
                  <a
                    href={currentProject.github}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Site
                  </a>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default SecondSlider;

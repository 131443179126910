import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import openZipper from "./App.js"

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

function ProjectSlider({ currentIndex, direction, paginate, projects, language, openZipper }) {
  if (!projects || projects.length === 0) {
    return <div>No projects available.</div>;
  }

  const currentProject = projects[currentIndex];
  if (!currentProject) {
    return <div>Invalid project index.</div>;
  }

  return (
    <div className="project-slider">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          className="project-slide"
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset }) => {
            if (offset.x > 100) paginate(-1); // Swipe right to go previous
            if (offset.x < -100) paginate(1); // Swipe left to go next
          }}
        >
          <div
            className="card bg-dark text-white bg-opacity-75"
            style={{
              width: "90%",
              maxWidth: "80vh",
              maxWidth:"400px",
              top: "20px",
            }}
          >
            {/* Image as card header */}
            {currentProject?.titleimage && currentProject.titleimage !== "" && (
              <img
                src={currentProject.titleimage}
                alt={currentProject?.title[language] || "Default Title"}
                className="card-img-top"
                style={{}}
              />
            )}

            {/* Card body */}
            <div className="card-body">
              <h5 className="card-title">
                {currentProject?.title[language] || "Default Title"}
              </h5>
              <p className="card-text">
                {currentProject?.description[language] || "No description available."}
              </p>
              <div className="row">

              {currentProject?.site && (
                <div className="col">
                  <a
                    href={currentProject.github}
                    className="btn btn-secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    {language === "fr" ? "Voir la source" : "View Source"}
                  </a>
                </div>
              )}
              {currentProject?.site && (
                  <div className="col mx-auto"></div>
              )}
    
              <div className="col">
                <button
                  className="btn btn-secondary"
                  onClick={openZipper}
                  >
                  {language === "fr" ? "Voir détails" : "See details"}
                </button>
              </div>
            </div>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default ProjectSlider;

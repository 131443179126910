export function initializeCanvas(canvas) {
  const ctx = canvas.getContext("2d");

  // Make the canvas full screen
  const resizeCanvas = () => {
    canvas.height = window.innerHeight;
    canvas.width = window.innerWidth;

    // Set an initial background color or gradient
    ctx.fillStyle = "rgba(0, 10, 0, 1)"; // Dark greenish background
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  resizeCanvas(); // Set initial size
  window.addEventListener("resize", resizeCanvas);

  // Characters for the rain
  const matrix =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
  const characters = matrix.split("");

  const font_size = 10;
  let columns = canvas.width / font_size; // Number of columns for the rain
  let drops = Array.from({ length: columns }, () => 1); // Drops array

  // Draw the characters
  function draw() {
    ctx.fillStyle = "rgba(0, 10, 0, 0.04)"; // Translucent background for fading effect
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = "#1E8800"; // Text color
    ctx.font = `${font_size}px arial`;

    for (let i = 0; i < drops.length; i++) {
      const text = characters[Math.floor(Math.random() * characters.length)];
      ctx.fillText(text, i * font_size, drops[i] * font_size);

      if (drops[i] * font_size > canvas.height && Math.random() > 0.975) {
        drops[i] = 0; // Reset to the top
      }

      drops[i]++;
    }
  }

  const interval = setInterval(draw, 35);

  // Cleanup function
  return () => {
    clearInterval(interval); // Stop the interval when unmounting
    window.removeEventListener("resize", resizeCanvas); // Remove event listener
  };
}
